import React from "react"

export const TECHNOLOGIES = [
  { name: "React", icon: "react.svg" },
  { name: "Python", icon: "python.svg" },
  { name: "MongoDB", icon: "mongodb.svg" },
  { name: "Figma", icon: "icon-figma.svg" },
  { name: "AWS", icon: "aws.svg" },
]

export const STAGES = [
  { id: 1, name: "Inside the LaSoft team" },
  { id: 2, name: "Inside the “Cultivate people” team" },
  { id: 3, name: "With real users" },
]

export const TEAM = [
  { id: 1, name: "Sales team", image: "sales", className: "green" },
  {
    id: 2,
    name: "Business Analysts",
    image: "business-analyst",
    className: "green",
  },
  {
    id: 3,
    name: "Project Managers",
    image: "project-manager",
    className: "green",
  },
  {
    id: 4,
    name: "Product Designers",
    image: "product-designer",
    className: "black",
  },
  {
    id: 5,
    name: "Solution Architects",
    image: "solution-architect",
    className: "navy",
  },
  {
    id: 6,
    name: "Frontend developers",
    image: "frontend-developer",
    className: "blue",
  },
  {
    id: 7,
    name: "Back-end developers",
    image: "backend-developer",
    className: "blue",
  },
  {
    id: 8,
    name: "Back-end developers",
    image: "backend-developer-2",
    className: "blue",
  },
  { id: 9, name: "DevOps", image: "devops", className: "light-blue" },
  { id: 10, name: "QA specialists", image: "qa", className: "orange" },
]

export const hours = [1, 2, 3, 4, 5, 6, 7, 8]

export const FEATURE_LIST = [
  {
    id: 1,
    title: "Integration of real–time database + Data aggregation",
    text: (
      <p>
        The product's core value is salary data for thousands of positions in
        dozens of countries. Previously, the data were updated from different
        sources and had various forms, so we unified this data and made the
        update process automatic.
      </p>
    ),
    screenshots: [
      {
        name: "bot",
        width: "305",
        height: "370",
      },
      {
        name: "mid",
        width: "328",
        height: "373",
      },
      {
        name: "top",
        width: "310",
        height: "359",
      },
    ],
  },
  {
    id: 2,
    title: "Automatic management of reviews",
    text: (
      <>
        <p>
          Users can independently (without consultants) conduct salary reviews
          using the product. By entering their data, uploading points, and
          calculating the value of the salary review, the platform independently
          predicts an appropriate salary level for each employee.
        </p>
        <p>
          Even if the HR manager raises the salary by a specific amount, the
          system will inform whether such amount will correspond to the
          realities of the market in which the company operates and for this
          particular position with its functionality.
        </p>
      </>
    ),
    screenshots: [
      {
        name: "bot",
        width: "464",
        height: "481",
      },
      {
        name: "top",
        width: "291",
        height: "645",
      },
    ],
  },
  {
    id: 3,
    title: "Integration with other HR systems",
    text: (
      <p>
        To avoid abandoning users' previous developments, we have developed an
        API for connecting to various HR systems. This solution allowed
        uploading of data for calculations and greatly simplified the work of HR
        managers.
      </p>
    ),
    screenshots: [
      {
        name: "bot",
        width: "276",
        height: "593",
      },
      {
        name: "mid",
        width: "202",
        height: "125",
      },
      {
        name: "top",
        width: "263",
        height: "287",
      },
    ],
  },
  {
    id: 4,
    title: "Smart user management",
    text: (
      <p>
        The salary review process requires approval from different employees,
        from the HR manager to the financial manager and CEO. Therefore, we have
        developed several types of system users so that decisions are made
        quickly.
      </p>
    ),
    screenshots: [
      {
        name: "bot",
        width: "479",
        height: "478",
      },
      {
        name: "top",
        width: "428",
        height: "527",
      },
    ],
  },
]

export const ARCHITECTURES = [
  {
    id: 1,
    title: "Monolith application",
    text: "We have developed the main functionality into one application.",
    image: "monolith-application.svg",
  },
  {
    id: 2,
    title: "Micro–services",
    text:
      "We integrated third-party API using micro–services that reduced the load of the main server.",
    image: "microservices.svg",
  },
  {
    id: 3,
    title: "Real–time big data",
    text:
      "We used web sockets for big data live working. Users can edit big data at the same time as others.",
    image: "big-data@3x.png",
  },
]
