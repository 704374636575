// extracted by mini-css-extract-plugin
export var architecture = "kamsa-module--architecture--a2cff";
export var architectureCard = "kamsa-module--architectureCard--644c2";
export var architectureTypes = "kamsa-module--architectureTypes--fdac6";
export var billetShadow = "kamsa-module--billet-shadow--e3135";
export var clientProduct = "kamsa-module--clientProduct--25243";
export var colorScheme__background__grayPale = "kamsa-module--colorScheme__background__gray-pale--74a04";
export var details = "kamsa-module--details--86248";
export var features = "kamsa-module--features--802eb";
export var featuresList = "kamsa-module--featuresList--2fd15";
export var h4 = "kamsa-module--h4--579aa";
export var heading = "kamsa-module--heading--06259";
export var headingImage = "kamsa-module--headingImage--88e9d";
export var icomoon = "kamsa-module--icomoon--272d3";
export var imageBlock = "kamsa-module--imageBlock--f2de1";
export var imageWrapper = "kamsa-module--imageWrapper--bdc77";
export var imgWrapper = "kamsa-module--imgWrapper--344a7";
export var job = "kamsa-module--job--85f71";
export var kamsaBlock = "kamsa-module--kamsaBlock--e12aa";
export var logoKamsa = "kamsa-module--logoKamsa--b635d";
export var lowercase = "kamsa-module--lowercase--6d5d3";
export var membersContainer = "kamsa-module--membersContainer--bd0bb";
export var membersHours = "kamsa-module--membersHours--71030";
export var mobilePicsWrapper = "kamsa-module--mobilePicsWrapper--5318a";
export var outcomes = "kamsa-module--outcomes--d4bad";
export var outcomesImg = "kamsa-module--outcomesImg--ef870";
export var outcomesText = "kamsa-module--outcomesText--f5b3b";
export var pic_1 = "kamsa-module--pic_1--c0572";
export var pic_2 = "kamsa-module--pic_2--4de6d";
export var picsWrapper = "kamsa-module--picsWrapper--6938b";
export var pl = "kamsa-module--pl--792c6";
export var pr = "kamsa-module--pr--a3f79";
export var productChallenge = "kamsa-module--productChallenge--60e50";
export var quotes = "kamsa-module--quotes--67d69";
export var shine = "kamsa-module--shine--b6022";
export var stages = "kamsa-module--stages--2eff6";
export var stagesWrapper = "kamsa-module--stagesWrapper--56f68";
export var strong = "kamsa-module--strong--4ce60";
export var technologiesBlock = "kamsa-module--technologiesBlock--92907";
export var textBlock = "kamsa-module--textBlock--aa958";
export var transition = "kamsa-module--transition--21f1e";
export var transitionBackground = "kamsa-module--transition-background--2d746";
export var transitionReverse = "kamsa-module--transition-reverse--ce6ea";
export var twoColumns = "kamsa-module--twoColumns--b5498";
export var uppercase = "kamsa-module--uppercase--770e3";